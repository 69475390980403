
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { integerMask, currencyMask, percentageMask } from '@/models/interface/Masks'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import Commission from '@/models/Commission'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import ViewModel from '@/models/ViewModel'
import AccountPicker from '@/components/AccountPicker/AccountPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import InvoicePicker from '@/components/InvoicePicker/InvoicePicker.vue'
import Invoice from '@/models/Invoice'
import User from '@/models/User'
import CommissionRule from '@/models/CommissionRule'
import WebMessage from '@/models/WebMessage'
import MediaPlanPicker from '../Expenses/components/MediaPlanPicker.vue'

// TODO james continue here
// last thing done was geting the user with his commission rules
// now we need to use this rules to calculate the commission amount

@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    FormInput,
    MediaPlanPicker,
    CompanyPicker,
    UserPicker,
    AccountPicker,
    DatePicker,
    SelectPicker,
    CheckboxInput,
    FooterNav,
    InvoicePicker,
  },
})
export default class CommissionEdit extends ViewModel {
  @Ref() readonly formWizard!: any

  public busy = true

  public step: number = 1

  public commission: Commission = new Commission()

  public payout_options = [
    { name: 'Net', value: 'net' },
    { name: 'Gross', value: 'gross' },
  ]

  public frequency_options = [
    { name: 'Quarter', value: 'quarter' },
    { name: 'Year', value: 'year' },
    { name: 'Month', value: 'month' },
  ]

  public temp_commission_rule: any = null

  // watch temp_commission_rule change and add to commission.commission_rule_ids
  @Watch('temp_commission_rule')
  public onTempCommissionChange(val: any) {
    if (val) {
      this.commission.commission_rule_ids = [val.id]
    }
  }

  // watch commission.user_id changes
  @Watch('commission.user_id')
  public onUserChange(id: any) {
    if (id) this.getUser(id)
  }

  public get getUserRuleOptions() {
    if (!this.commission.user.commission_rules || !this.commission.user.commission_rules.length) return false
    return this.commission.user.commission_rules.map((rule: CommissionRule) => {
      let type = 'Root'
      if (rule.commissionable_id) {
        if (rule.commissionable_type?.includes('Company')) {
          type = 'Business Entity'
        }
        if (rule.commissionable_type?.includes('MediaPlan')) {
          type = 'MediaPlan'
        }
      }
      return {
        name: type,
        value: rule.id,
      }
    })
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  public get masks() {
    return {
      integerMask,
      currencyMask,
      percentageMask,
    }
  }

  public mounted() {
    this.busy = false

    if (this._isEditing) {
      this.busy = true
      this.loading = true
      Commission.find(this.$route.params.id).then((response: any) => {
        this.commission = response
        this.temp_commission_rule = response.rules[0]

        this.commission.user = {
          commission_rules: response.rules,
        }
        this.selectRule({ value: response.rules[0].id })
        this.getUser(response.user_id)
        this.busy = false
        setTimeout(() => {
          this.loading = false
          this.formWizard.activateAll()
        }, 1000)
      })
    } else {
      // return last page
      // TODO remove this if commission can be created here
      this.$router.back()
    }

    if (this.$route.query.invoice_id) {
      this.getInvoiceDetails(this.$route.query.invoice_id).then((response: any) => {
        let obj: any = localStorage.getItem('commission_page')
        if (obj) {
          obj = JSON.parse(obj)
          if (obj.user_id) {
            Vue.set(this.commission, 'user_id', obj.user_id)
          }

          localStorage.removeItem('commission_page')
        }
      })
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    return true
  }

  public async onSubmit() {
    this.loading = true
    if (this._isEditing) {
      this.commission.type = 'manual'
    }
    this.commission.save().then((response: any) => {
      if (response.status === 200) {
        this.$router.push({ name: 'commissions' })
      }
    })
  }

  public onInvoiceSelected(invoice: any) {
    // this.commission.invoice_id = invoice.id
    // this.commission.invoice = invoice
    this.getInvoiceDetails(invoice.value)
  }

  public getInvoiceDetails(id: any) {
    this.loading = true
    return Invoice.find(id).then((response: any) => {
      this.commission.invoice = response
      this.commission.period = response.created_at
      Vue.set(this.commission, 'invoice_id', response.id)
      Vue.set(this.commission, 'user_id', response.sales_rep_id)
      this.commission.media_plan_item_ids = response.media_plan_item_ids || []
      this.commission.user = response.sales_rep
      this.commission.amount = response.total.toFixed(2)
      this.loading = false
      return response
    })
  }

  public getUser(id: string) {
    CommissionRule.checkUserComissionRules(id).then((response: any) => {
      if (!response.commission_rules.length) {
        WebMessage.confirm(
          'The user from the selected invoice has no commission rules, set one now.',
          'No Commission Rule',
          { okTitle: 'Create Rule' },
        ).then((response: any) => {
          if (response) {
            // @ts-ignore
            localStorage.setItem(
              'commission_page',
              JSON.stringify({ invoice_id: this.commission.invoice_id, user_id: id }),
            )
            this.$router.push({ name: 'commission-rule', query: { user_id: id } })
          }
        })
      }
      this.commission.user = response
    })
  }

  public selectRule(r: any) {
    let rule = this.commission.user.commission_rules.find((rule: any) => rule.id === r.value)

    Vue.set(this, 'temp_commission_rule', rule)
  }
}
