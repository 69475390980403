import { render, staticRenderFns } from "./CommissionEdit.vue?vue&type=template&id=6ee85268&"
import script from "./CommissionEdit.vue?vue&type=script&lang=ts&"
export * from "./CommissionEdit.vue?vue&type=script&lang=ts&"
import style0 from "./CommissionEdit.vue?vue&type=style&index=0&id=6ee85268&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports